import { PlayButton } from 'Roblox';
import React, { useCallback, useState, useMemo } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { uuidService } from 'core-utilities';
import { TSystemFeedbackService } from 'react-style-guide';
import { groupEventsConfig } from '../translation.config';
import eventsService from '../services/eventsService';
import { VirtualEvent, GameDetails } from '../types';
import groupEventsConstants from '../constants/groupEventsConstants';
import AnalyticsEvents from '../utils/analytics';

const { launchGame } = PlayButton;
const { eventRsvpStatus } = groupEventsConstants;

export type GroupEventButtonProps = {
  event: VirtualEvent;
  gameDetails: GameDetails | undefined;
  systemFeedbackService: TSystemFeedbackService;
} & WithTranslationsProps;

const GroupEventButton = ({
  event,
  gameDetails,
  systemFeedbackService,
  translate
}: GroupEventButtonProps): JSX.Element | null => {
  const [userHasRsvpd, setUserHasRsvpd] = useState(event.userRsvpStatus === eventRsvpStatus.going);

  const setRsvpToGoing = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await eventsService.postRsvpStatus(event.id, eventRsvpStatus.going);
        setUserHasRsvpd(true);
        AnalyticsEvents.sendVirtualEventRSVPEvent(
          event.id,
          event.universeId,
          eventRsvpStatus.going
        );
      } catch {
        systemFeedbackService.warning(translate('NetworkError'));
      }
    },
    [event.id, event.universeId, setUserHasRsvpd, systemFeedbackService, translate]
  );

  const setRsvpToNotGoing = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await eventsService.postRsvpStatus(event.id, eventRsvpStatus.notGoing);
        setUserHasRsvpd(false);
        AnalyticsEvents.sendVirtualEventRSVPEvent(
          event.id,
          event.universeId,
          eventRsvpStatus.notGoing
        );
      } catch {
        systemFeedbackService.warning(translate('NetworkError'));
      }
    },
    [event.id, event.universeId, setUserHasRsvpd, systemFeedbackService, translate]
  );

  const joinEvent = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!gameDetails) return;
      const sessionId = uuidService.generateRandomUuid();
      launchGame(
        gameDetails.rootPlaceId.toString(),
        gameDetails.rootPlaceId.toString(),
        '',
        undefined,
        {
          eventName: 'virtualEventJoinGame',
          ctx: 'virtualEvents',
          eventJoinSessionId: sessionId
        }
      );
      AnalyticsEvents.sendVirtualEventJoinedEvent(event.id, event.universeId, sessionId);
    },
    [gameDetails, event.id, event.universeId]
  );

  const isEventLive = useMemo(() => Date.now() > Date.parse(event.eventTime.startUtc), [event]);

  if (isEventLive) {
    return (
      <button type='button' className='group-event-join-button btn-growth-md' onClick={joinEvent}>
        {translate('JoinEvent')}
      </button>
    );
  }
  if (userHasRsvpd) {
    return (
      <button
        type='button'
        className='group-event-join-button btn-secondary-md'
        onClick={setRsvpToNotGoing}>
        {translate('UnfollowEvent')}
      </button>
    );
  }
  return (
    <button type='button' className='group-event-join-button btn-cta-md' onClick={setRsvpToGoing}>
      {translate('NotifyMe')}
    </button>
  );
};

export default withTranslations(GroupEventButton, groupEventsConfig);
