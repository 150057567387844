import type { TranslationConfig } from 'react-utilities';

export const groupEventsConfig: TranslationConfig = {
  common: [],
  feature: 'Feature.VirtualEvents'
};

export const groupsConfig: TranslationConfig = {
  common: [],
  feature: 'Feature.Groups'
};

export default { groupEventsConfig, groupsConfig };
